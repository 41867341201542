import React, { useEffect, useRef } from "react";
import styles from "./Snowflakes.module.css";

const snowflakeImages = [
  "snowflake1.png",
  "snowflake2.png",
  "snowflake3.png",
  "snowflake4.png",
  "snowflake5.png",
];

let loadedImages = 0;
const preloadedImages = [];

function preloadImages(callback) {
  snowflakeImages.forEach((src, index) => {
    const img = new Image();
    img.onload = () => {
      loadedImages++;
      preloadedImages[index] = img;
      if (loadedImages === snowflakeImages.length) {
        callback();
      }
    };
    img.src = src;
  });
}

function getRandomImage() {
  return preloadedImages[Math.floor(Math.random() * preloadedImages.length)];
}

export default function Snowflakes({
  maxCount = 100,
  minSize = 50,
  maxSize = 100,
}) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let rafId;

    function resizeCanvas() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }

    resizeCanvas();

    const snowflakes = [];

    class Snowflake {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.speed = 1 + Math.random() * 3;
        this.flakeCanvas = document.createElement("canvas");
        this.flakeCtx = this.flakeCanvas.getContext("2d");
        this.drawFlake();
      }

      drawFlake() {
        this.image = getRandomImage();
        this.rotation = Math.random() * 2 * Math.PI;
        const windowScale = Math.max(0.5, window.innerWidth / 1600);
        this.size = Math.floor(
          (minSize + Math.random() * (maxSize - minSize)) * windowScale
        );
        this.flakeCanvas.width = this.size;
        this.flakeCanvas.height = this.size;
        this.flakeCtx.clearRect(0, 0, this.size, this.size);
        this.flakeCtx.save();
        this.flakeCtx.translate(this.size / 2, this.size / 2);
        this.flakeCtx.rotate(this.rotation);
        this.flakeCtx.drawImage(
          this.image,
          -this.size / 2,
          -this.size / 2,
          this.size,
          this.size
        );
        this.flakeCtx.restore();
      }

      update() {
        this.y += this.speed;
        if (this.y > canvas.height + this.size) {
          this.y = -this.size;
          this.x = Math.random() * canvas.width;
        }
      }

      draw() {
        ctx.drawImage(
          this.flakeCanvas,
          this.x - this.size / 2,
          this.y - this.size / 2
        );
      }
    }

    const handleResize = () => {
      resizeCanvas();
      snowflakes.forEach((snowflake) => {
        snowflake.drawFlake();
        if (snowflake.x > canvas.width) {
          snowflake.x = Math.random() * canvas.width;
        }
        if (snowflake.y > canvas.height) {
          snowflake.y = -snowflake.size;
        }
      });
    };

    preloadImages(() => {
      for (let i = 0; i < maxCount; i++) {
        snowflakes.push(new Snowflake());
      }

      const animate = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        snowflakes.forEach((snowflake) => {
          snowflake.update();
          snowflake.draw();
        });
        rafId = requestAnimationFrame(animate);
      };

      animate();
      window.addEventListener("resize", handleResize);
    });

    return () => {
      cancelAnimationFrame(rafId);
      window.removeEventListener("resize", handleResize);
    };
  }, [maxCount, minSize, maxSize]);

  return <canvas ref={canvasRef} className={styles.root} />;
}
