import camelcaseKeys from "camelcase-keys";
import { API_URL } from "./constants";

const cache = {};

export async function privacyPolicyLoader({ request }) {
  const data = await getAll(request.signal, "privacy-policy-page");
  return {
    privacyPolicy: data[0].content,
  };
}

export async function termsAndConditionsLoader({ request }) {
  const data = await getAll(request.signal, "terms-and-conditions-page");
  return {
    termsAndConditions: data[0].content,
  };
}

async function getAll(signal, type) {
  const url = `${API_URL}/${type}/`;
  if (cache[url]) {
    return cache[url];
  }
  const data = await getData(signal, url);
  if (cache[url] === undefined) {
    cache[url] = data;
  }
  return data;
}

// async function getOne(signal, type, id) {
//   const url = `${API_URL}/${type}/${id}/`;
//   if (cache[url]) {
//     return cache[url];
//   }
//   const data = await getData(signal, url);
//   if (cache[url] === undefined) {
//     cache[url] = data;
//   }
//   return data;
// }

async function getData(signal, url) {
  let response;
  try {
    response = await fetch(url, {
      signal,
    });
  } catch (error) {
    console.log("Could not load", error);
    throw new Error("Could not load", url);
  }
  if (response.status !== 200) {
    throw new Error("Not found", url);
  }
  try {
    const data = await response.json();
    return camelcaseKeys(data);
  } catch (error) {
    console.log("Could not parse", error);
    throw new Error("Could not parse", url);
  }
}
