import { useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { useEffect } from "react";

export default function ErrorPage({ body = "This page cannot be found." }) {
  let error = useRouteError();

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <div>
      <h2>Sorry!</h2>
      <div>
        <p>{body}</p>
        <p>
          Please click <a href="/">here</a> to go to the homepage
        </p>
      </div>
    </div>
  );
}
