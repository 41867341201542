import Legals from "./Legals";
import { useLoaderData } from "react-router-dom";

export default function TermsAndConditions() {
  const { termsAndConditions } = useLoaderData();
  return (
    <Legals>
      <h1>Terms and Conditions</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: termsAndConditions,
        }}
      ></div>
    </Legals>
  );
}
