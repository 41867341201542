import { createContext } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { privacyPolicyLoader, termsAndConditionsLoader } from "./loaders";
import { ScrollRestoration } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import ForceError from "./ForceError";
import HoldingPage from "./HoldingPage";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage body="An error has occured" />,
    Component: Root,
    children: [
      {
        index: true,
        Component: HoldingPage,
      },
      {
        path: "terms-and-conditions",
        Component: TermsAndConditions,
        loader: termsAndConditionsLoader,
      },
      {
        path: "privacy-policy",
        Component: PrivacyPolicy,
        loader: privacyPolicyLoader,
      },
      {
        path: "error",
        Component: ForceError,
      },
    ],
  },
  {
    path: "*",
    Component: ErrorPage,
  },
]);

export const AppContext = createContext();

export default function App() {
  return <RouterProvider router={router} />;
}

function Root() {
  let lang = "gb";
  if (
    window.location.hostname === "djsanta-reeses.de" ||
    window.location.search.includes("lang=de")
  ) {
    lang = "de";
  }

  return (
    <AppContext.Provider value={{ lang }}>
      <ScrollRestoration />
      <Outlet />
    </AppContext.Provider>
  );
}
