import { AppContext } from "./App";
import { ReactComponent as Logo } from "./logo.svg";
import { useContext } from "react";
import Snowflakes from "./Snowflakes";
import styles from "./HoldingPage.module.css";
import translations from "./translations.json";

export default function HoldingPage() {
  const { lang } = useContext(AppContext);

  const heading = translations.holdingPage[lang].heading;
  const text = translations.holdingPage[lang].text;

  return (
    <>
      <Snowflakes />
      <div className={styles.root}>
        <div className={styles.box}>
          <h1 className={styles.heading}>{heading}</h1>
          <p
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
        <Logo className={styles.logo} />
      </div>
    </>
  );
}
