import Legals from "./Legals";
import { useLoaderData } from "react-router-dom";

export default function PrivacyPolicy() {
  const { privacyPolicy } = useLoaderData();
  return (
    <Legals>
      <h1>Privacy Policy</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: privacyPolicy,
        }}
      ></div>
    </Legals>
  );
}
